@import url(https://fonts.googleapis.com/css2?family=Lato&family=Poppins&family=Raleway&family=Roboto&display=swap);
* {
    margin: 0;
    padding: 0;
    font-family: "Roboto", "Lato", Arial, sans-serif;
}

body {
    font-size: 22px;
}

.container {
    margin: 2.75rem 3.25rem;
}

a {
    text-decoration: none;
    color: #45458b;
}

.paragraph {
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

.heading {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    font-family: "Poppins", "Raleway", Arial, sans-serif;
}

a:hover {
    text-decoration: underline;
}

.profile-photo {
    height: 10rem;
    border: 0.3rem solid #9f989b;
    border-radius: 50%;
}

.block-divider .block-left, .block-divider .block-right {
    display: inline-block;
}

